// fifty-royal-esports/src/components/common/NotFound.js

// Componente para rutas no encontradas
const NotFound = () => (
    <div className="text-center p-8">
      <h1 className="text-4xl font-bold text-red-500">404</h1>
      <p className="text-xl text-gray-400">Página no encontrada.</p>
    </div>
  );
  
  export default NotFound; // Agrega la exportación predeterminada
  