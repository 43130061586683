import React from 'react';
import { FaTools } from 'react-icons/fa'; // Asegúrate de tener 'react-icons' instalado

function App() {
  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: '#1F2937', // bg-gray-800 en Tailwind equivalente en CSS puro
      textAlign: 'center',
      color: '#fff' // Texto en blanco para mejor contraste con el fondo oscuro
    }}>
      <FaTools style={{
        fontSize: '4rem',
        color: '#8A2BE2', // Efecto neon violeta
        animation: 'spin 4s linear infinite',
        textShadow: '0 0 10px #8A2BE2, 0 0 20px #8A2BE2, 0 0 40px #8A2BE2, 0 0 80px #8A2BE2' // Brillo tipo neon
      }} />
      <h1 style={{
        fontSize: '2rem',
        color: '#E5E7EB', // Color claro para contraste
        marginTop: '20px'
      }}>Sección en Construcción</h1>
      <p style={{
        fontSize: '1.2rem',
        maxWidth: '80%',
        lineHeight: '1.5'
      }}>
        Estamos trabajando para traerte nuevas funcionalidades. ¡Vuelve pronto!
      </p>
      <style>
        {`
          @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
}

export default App;
