import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { FaGamepad } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Correctamente incluido
import { Button } from '../common/Button';
import { Card, CardHeader, CardContent } from '../common/Card';

const EsportsInfo = () => {
  const games = [
    { 
      icon: <FaGamepad />, 
      name: 'Clash Royale', 
      description: 'Compete en torneos y guerras de clanes con premios.', 
      route: '/ClashRoyale/Dashboard'
    },
    { 
      icon: <FaGamepad />, 
      name: 'League of Legends', 
      description: 'Únete a nuestro equipo competitivo y participa en ligas regionales.', 
      route: '/LeagueOfLegends/Dashboard'
    },
    { 
      icon: <FaGamepad />, 
      name: 'Fortnite', 
      description: 'Eventos semanales y competiciones con premios en efectivo.', 
      route: '/Fortnite/Dashboard'
    }
  ];

  return (
    <div className="bg-gray-900 min-h-screen text-white p-8">
      <Helmet>
        <title>eSports en Fifty Royal</title>
        <meta name="description" content="Únete a nuestra comunidad de esports y participa en juegos como Clash Royale, League of Legends y más. ¡Gana premios y mejora tus habilidades!" />
      </Helmet>
      
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="max-w-4xl mx-auto"
      >
        <motion.header className="text-center mb-12">
          <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-green-400 via-blue-500 to-purple-600">
            ¡Explora los eSports en Fifty Royal!
          </h1>
          <p className="text-xl mt-4">
            Participa en una variedad de juegos y compite al más alto nivel. Descubre qué juego es el adecuado para ti y únete a nuestra comunidad.
          </p>
        </motion.header>

        {games.map((game, index) => (
          <GameCard key={index} game={game} />
        ))}

      </motion.div>
    </div>
  );
};

const GameCard = ({ game }) => {
  const navigate = useNavigate(); // Correctamente ubicado en GameCard

  return (
    <Card className="bg-gray-800 bg-opacity-70 my-4 p-6 rounded-lg">
      <CardHeader>
        <div className="flex items-center">
          <div className="text-3xl mr-6">{game.icon}</div>
          <h3 className="text-2xl font-bold">{game.name}</h3>
        </div>
      </CardHeader>
      <CardContent>
        <p>{game.description}</p>
        <Button onClick={() => navigate(game.route)} className="mt-4 bg-blue-500 hover:bg-blue-600">
          Más Info
        </Button>
      </CardContent>
    </Card>
  );
};

export default EsportsInfo;
