import React from 'react';

const ProfilePage = () => {
  const userData = JSON.parse(sessionStorage.getItem('userData'));
  const userRoles = JSON.parse(sessionStorage.getItem('userRoles')); // Obtén los roles desde sessionStorage

  if (!userData) {
    return <div>Error: No se encontraron datos del usuario. Por favor, inicia sesión.</div>;
  }

  return (
    <div
      style={{
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#0f172a', // Fondo oscuro
        color: 'white', // Texto blanco
        minHeight: '100vh', // Ocupa toda la altura de la ventana
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        className="neon-violet"
        style={{
          width: '150px',
          height: '150px',
          marginBottom: '20px',
          borderRadius: '50%',
          overflow: 'hidden',
        }}
      >
        <img
          src={`https://cdn.discordapp.com/avatars/${userData.id}/${userData.avatar}.png`}
          alt="Avatar del usuario"
          style={{ width: '100%', height: '100%' }}
        />
      </div>
      <h1 style={{ fontSize: '2rem', marginBottom: '10px' }}>Perfil del Usuario</h1>
      <h2 style={{ fontSize: '1.5rem', marginBottom: '20px' }}>
        {userData.username}#{userData.discriminator}
      </h2>
      <div
        style={{
          textAlign: 'left',
          backgroundColor: '#1e293b',
          padding: '20px',
          borderRadius: '10px',
          width: '90%',
          maxWidth: '500px',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div style={{ marginBottom: '15px' }}>
          <p><strong>ID:</strong> {userData.id}</p>
        </div>
        {userData.email && (
          <div style={{ marginBottom: '15px' }}>
            <p><strong>Email:</strong> {userData.email}</p>
          </div>
        )}
        <div style={{ marginBottom: '15px' }}>
          <p><strong>Idioma Preferido:</strong> {userData.locale}</p>
        </div>
        <div style={{ marginBottom: '15px' }}>
          <p><strong>Autenticación 2FA:</strong> {userData.mfa_enabled ? 'Habilitada' : 'Deshabilitada'}</p>
          {userData.mfa_enabled ? null : (
            <p style={{ color: '#facc15', marginTop: '10px' }}>
              ⚠️ Recomendamos habilitar la autenticación 2FA para proteger tu cuenta. Puedes aprender cómo hacerlo aquí:{' '}
              <a
                href="https://support.discord.com/hc/es-419/articles/219576828-Configurando-la-Autenticaci%C3%B3n-de-m%C3%BAltiples-factores"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#38bdf8', textDecoration: 'underline' }}
              >
                Configurar 2FA
              </a>
            </p>
          )}
        </div>
        {userRoles && userRoles.length > 0 && (
          <div style={{ marginTop: '20px', marginBottom: '15px' }}>
            <p><strong>Roles:</strong></p>
            <ul style={{ listStyleType: 'none', padding: '0', margin: '0' }}>
              {userRoles.map((roleId, index) => (
                <li key={index} style={{ marginBottom: '5px' }}>
                  <span style={{ backgroundColor: '#3b82f6', padding: '5px 10px', borderRadius: '5px', color: '#ffffff' }}>
                    {roleId} {/* Aquí puedes mapear los IDs de roles a nombres si tienes esa información */}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfilePage;
