import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const OAuthKYC = ({ children, requiredGuildId }) => {
  const [isAllowed, setIsAllowed] = useState(null); // Estado para almacenar el permiso
  const accessToken = sessionStorage.getItem('accessToken'); // Obtén el token de acceso de OAuth
  const location = useLocation(); // Obtén la ubicación actual para redirigir después del login

  useEffect(() => {
    const validateUser = async () => {
      console.log('Iniciando validación del usuario...');

      // Si no hay un token, redirigir al login
      if (!accessToken) {
        console.error('No se encontró un token de acceso. Redirigiendo al login...');
        sessionStorage.setItem('redirectAfterLogin', location.pathname); // Guardar la ruta actual
        window.location.href = '/login'; // Redirigir al login
        return;
      }

      try {
        // Obtener los servidores del usuario desde Discord
        const response = await fetch('https://discord.com/api/users/@me/guilds', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          if (response.status === 401) {
            console.error('Token expirado o inválido. Redirigiendo al login...');
            sessionStorage.removeItem('accessToken'); // Limpia el token si es inválido
            sessionStorage.setItem('redirectAfterLogin', location.pathname); // Guardar la ruta actual
            window.location.href = '/login'; // Redirigir al login
            return;
          }
          throw new Error(`Error al obtener los servidores del usuario: ${response.statusText}`);
        }

        // Procesar la lista de guilds
        const guilds = await response.json();
        console.log('Guilds del usuario:', guilds);

        // Verificar si el usuario pertenece al servidor requerido
        const isInGuild = guilds.some((guild) => guild.id === requiredGuildId);
        console.log('¿Usuario en el servidor requerido?', isInGuild);

        setIsAllowed(isInGuild);
      } catch (error) {
        console.error('Error validando usuario:', error);
        setIsAllowed(false); // Denegar acceso si ocurre un error
      }
    };

    validateUser();
  }, [accessToken, requiredGuildId, location.pathname]);

  if (isAllowed === null) {
    return <div>Cargando validación de usuario...</div>;
  }

  if (!isAllowed) {
    return <Navigate to="/no-access" replace />;
  }

  return children;
};

export default OAuthKYC;
