import React from 'react';
import { FaSignInAlt } from 'react-icons/fa'; // Importa el ícono de login

const AUTH_URL = process.env.REACT_APP_MORO_AUTH_URL;

function OAuthLogin() {
  const handleLogin = () => {
    console.log('Iniciando proceso de autenticación...');
    
    if (!AUTH_URL) {
      console.error('ERROR: La URL de autenticación no está definida correctamente en REACT_APP_MORO_AUTH_URL.');
      console.warn('Por favor, revisa tu archivo .env y asegúrate de que la variable REACT_APP_MORO_AUTH_URL esté configurada.');
      return;
    }

    console.log('URL generada para autenticación:', AUTH_URL); // Verifica que la URL generada sea la esperada
    window.location.href = AUTH_URL; // Redirige al usuario a la página de Discord
  };

  return (
    <button
      onClick={handleLogin}
      className="bg-purple-500 text-white px-4 py-2 rounded flex items-center hover:bg-purple-700 transition duration-300"
    >
      <FaSignInAlt size={20} className="mr-2" /> {/* Ícono antes del texto */}
      Login
    </button>
  );
}

export default OAuthLogin;
