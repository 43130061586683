import React from 'react';
import Sidebar from './Header'; // Asumiendo que Sidebar está en el archivo Header
import FeedbackButton from './FeedbackButton'; // Asegúrate de tener la ruta correcta

const Layout = ({ children }) => {
  return (
    <div className="flex min-h-screen bg-gray-900 text-white">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-grow p-4 transition-all duration-300 ml-16 sm:ml-64 overflow-x-auto">
        {children}
      </div>

      {/* Feedback Button */}
      <FeedbackButton />
    </div>
  );
};

export default Layout;
