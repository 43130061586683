import React from 'react';
import FiftyInteractiveUniverse from './FiftyUniverse';
import Timeline from '../common/Timeline';

const HomePage = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-900 text-white">
      <FiftyInteractiveUniverse />
      <Timeline />
    </div>
  );
};

export default HomePage;
