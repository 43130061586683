import React, { useEffect, useState } from 'react';
import { FaFistRaised, FaTrophy, FaUsers, FaGamepad, FaHandshake} from 'react-icons/fa';

const PointsExplanation = () => {
  return (
    <div className="my-6 px-4 py-5 bg-gray-900 rounded-lg shadow-lg text-center">
      <h2 className="text-2xl font-semibold text-yellow-500">Suma Puntos en el Clan</h2>
      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="bg-black rounded-lg p-3 flex flex-col items-center justify-center shadow-xl hover:shadow-2xl transition-shadow duration-300 neon-violet">
          <FaFistRaised className="text-yellow-300 text-3xl"/>
          <p className="text-white mt-2">Guerra de Clanes</p>
        </div>
        <div className="bg-black rounded-lg p-3 flex flex-col items-center justify-center shadow-xl hover:shadow-2xl transition-shadow duration-300 neon-violet">
          <FaTrophy className="text-yellow-300 text-3xl"/>
          <p className="text-white mt-2">Ladder</p>
        </div>
        <div className="bg-black rounded-lg p-3 flex flex-col items-center justify-center shadow-xl hover:shadow-2xl transition-shadow duration-300 neon-violet">
          <FaHandshake className="text-yellow-300 text-3xl"/>
          <p className="text-white mt-2">2v2</p>
        </div>
        <div className="bg-black rounded-lg p-3 flex flex-col items-center justify-center shadow-xl hover:shadow-2xl transition-shadow duration-300 neon-violet">
          <FaUsers className="text-yellow-300 text-3xl"/>
          <p className="text-white mt-2">Amistosos</p>
        </div>
        <div className="bg-black rounded-lg p-3 flex flex-col items-center justify-center shadow-xl hover:shadow-2xl transition-shadow duration-300 neon-violet">
          <FaGamepad className="text-yellow-300 text-3xl"/>
          <p className="text-white mt-2">Torneos Internos</p>
        </div>
      </div>
    </div>
  );
};

const NotionTable = () => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/tableData.json');
        if (!response.ok) {
          throw new Error('Error al cargar los datos.');
        }
        const data = await response.json();
        setTableData(data);
      } catch (err) {
        setError(err.message || 'Error al cargar los datos.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="p-4 flex flex-col items-center bg-gray-800 text-white">
      <h1 className="text-3xl font-bold mb-2 text-yellow-500">Seguimiento de Recompensas - Clash Royale</h1>
      <PointsExplanation />
      {loading ? (
        <p className="text-lg text-gray-300">Cargando datos...</p>
      ) : error ? (
        <p className="text-lg text-red-500">Error: {error}</p>
      ) : (
        <div className="w-full overflow-x-auto">
          <table className="min-w-full table-auto border-collapse border border-gray-700">
            <thead>
              <tr className="bg-gray-800">
                <th className="border border-gray-700 p-4 text-left text-yellow-400">Jugador</th>
                <th className="border border-gray-700 p-4 text-left text-yellow-400">Tag</th>
                <th className="border border-gray-700 p-4 text-left text-yellow-400">Cantidad ganada</th>
                <th className="border border-gray-700 p-4 text-left text-yellow-400">Cantidad retirada</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr
                  key={index}
                  className={`hover:bg-gray-600 transition-all duration-200 ${
                    index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'
                  }`}
                >
                  <td className="border border-gray-700 p-4 whitespace-nowrap">{row.Jugador}</td>
                  <td className="border border-gray-700 p-4 whitespace-nowrap text-blue-400">{row.Tag}</td>
                  <td className="border border-gray-700 p-4 text-green-400">{row['Cantidad ganada']}</td>
                  <td className="border border-gray-700 p-4 text-red-400">{row['Cantidad retirada']}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default NotionTable;
