// src/routes.js
import React from 'react';
import HomePage from './components/home/HomePage';
import SSLDocumentation from './documents/SSLDocumentation';
import FiftyInteractiveUniverse from './components/home/FiftyUniverse';
import EsportsInfo from './components/verticals/EsportsInfo';
import RecordsInfo from './components/verticals/RecordsInfo';
import NewsInfo from './components/verticals/NewsInfo';
import NNdNandoPage from './components/FiftyRecords/artists/nNd_Nando/nNd_Nando';
import Dashboard from './components/ClashRoyale/Dashboard';
import FormClanContact from './components/ClashRoyale/fromClanContact';
import TrainingApp from './components/training/app';
import NotFound from './components/common/NotFound'; // Componente de 404
import PokemonTable from './components/Pokemon/CaptureMewProgress';
import NotionTable from './Notion/NotionTable';
import Academy from './components/Academy/Academy';
import ProfilePage from "./components/common/ProfilePage";

// Nuevos imports para OAuth2
import OAuthLogin from './components/OAuth2/OAuthLogin';
import OAuthCallback from './components/OAuth2/OAuthCallback';

// Importa OAuthKYC
import OAuthKYC from './components/OAuth2/OAuthKYC';

// Obtén el Guild ID desde la variable de entorno
const REQUIRED_GUILD_ID = process.env.REACT_APP_DISCORD_GUILD_ID;

const routes = [
  { path: '/', component: HomePage },
  {
    path: '/documents/SSL',
    component: (props) => (
      <OAuthKYC requiredGuildId={REQUIRED_GUILD_ID}>
        <SSLDocumentation {...props} />
      </OAuthKYC>
    ),
  },
  { path: '/fiftyuniverse', component: FiftyInteractiveUniverse },
  { path: '/esports-info', component: EsportsInfo },
  { path: '/records-info', component: RecordsInfo },
  { path: '/news-info', component: NewsInfo },
  { path: '/Nando', component: NNdNandoPage },
  { path: '/ClashRoyale/Dashboard', component: Dashboard },
  { path: '/contact-clans', component: FormClanContact },
  { path: '/training', component: TrainingApp },
  { path: '/CaptureMewProgress', component: PokemonTable },
  { path: '/RewardsTable', component: NotionTable },
  { path: '/Academy', component: Academy },
  
  // Nuevas rutas de OAuth2
  { path: '/login', component: OAuthLogin },
  { path: '/callback', component: OAuthCallback },
  { path: "/profile", component: ProfilePage },

  { path: '*', component: NotFound } // Ruta para 404
];

export default routes;
