import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const WelcomeDynamicBanner = () => {
  const [backgroundPosition, setBackgroundPosition] = useState('0% 50%');
  const [username, setUsername] = useState(''); // Almacena el nombre del usuario

  useEffect(() => {
    // Recuperar el nombre de usuario de sessionStorage
    const userData = JSON.parse(sessionStorage.getItem('userData'));
    if (userData && userData.username) {
      setUsername(userData.username); // Establece la parte antes del '#'
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundPosition((prevPosition) =>
        prevPosition === '0% 50%' ? '100% 50%' : '0% 50%'
      );
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="relative bg-gradient-to-br from-gray-900 via-gray-800 to-black text-white px-6 flex items-center justify-between w-full"
      style={{
        minHeight: '300px',
        backgroundSize: '200% 200%',
        backgroundPosition: backgroundPosition,
        transition: 'background-position 15s linear',
      }}
    >
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1, delay: 0.3 }}
        className="w-full text-left flex flex-col justify-center"
      >
        <h1 className="text-4xl md:text-5xl font-bold mb-4">
          ¡Bienvenido a <span className="text-yellow-500">Fifty Royal</span>
          {username ? `, ${username}` : ''}!
        </h1>
        <p className="text-lg md:text-xl text-gray-300 mb-6">
          Vive la experiencia del gaming competitivo y conecta con nuestra comunidad. ¡Haz historia con nosotros!
        </p>
      </motion.div>
    </motion.section>
  );
};

export default WelcomeDynamicBanner;
