import React, { useState, useEffect } from 'react';
import CustomRoutineCreator from './CustomRoutineCreator';
import ExerciseTimer from './ExerciseTimer';
import exercises from './exercises';

const modes = {
  standard: {
    name: 'Entrenamiento Estándar',
    exercises: exercises,
    restTime: 15,
    description: 'Un entrenamiento estándar con 30 segundos por ejercicio y 15 segundos de descanso activo entre ejercicios. Ideal para sesiones de ritmo moderado.'
  },
  tabata: {
    name: 'Entrenamiento Tabata',
    exercises: exercises.map(exercise => ({ ...exercise, duration: 20 })),
    restTime: 10,
    description: 'Entrenamiento de alta intensidad con 20 segundos de trabajo y 10 segundos de descanso activo. Perfecto para mejorar resistencia y fuerza en menos tiempo.'
  },
  custom: {
    name: 'Entrenamiento Personalizado',
    exercises: exercises.map(exercise => ({ ...exercise, duration: 45 })),
    restTime: 20,
    description: 'Un entrenamiento personalizado con 45 segundos por ejercicio y 20 segundos de descanso activo. Ideal para entrenamientos a tu propio ritmo.'
  }
};

function App() {
  const [selectedMode, setSelectedMode] = useState('');
  const [hasStarted, setHasStarted] = useState(false);
  const [customRoutine, setCustomRoutine] = useState(null);
  const [customRestTime, setCustomRestTime] = useState(15);
  const [savedRoutines, setSavedRoutines] = useState([]);

  useEffect(() => {
    const storedRoutines = localStorage.getItem('savedRoutines');
    if (storedRoutines) {
      setSavedRoutines(JSON.parse(storedRoutines));
    }
  }, []);

  const handleModeChange = (e) => {
    setSelectedMode(e.target.value);
    setHasStarted(false);
  };

  const handleRoutineCreated = (routine, routineName, restTime) => {
    const newRoutine = { name: routineName, exercises: routine, restTime };
    const updatedRoutines = [...savedRoutines, newRoutine];
    setSavedRoutines(updatedRoutines);
    localStorage.setItem('savedRoutines', JSON.stringify(updatedRoutines));
    setCustomRoutine(routine);
    setCustomRestTime(restTime);
  };

  const deleteRoutine = (routineName) => {
    const updatedRoutines = savedRoutines.filter(routine => routine.name !== routineName);
    setSavedRoutines(updatedRoutines);
    localStorage.setItem('savedRoutines', JSON.stringify(updatedRoutines));
  };

  const handleRestart = () => {
    setSelectedMode('');
    setHasStarted(false);
    setCustomRoutine(null);
  };

  const handleStartClick = () => {
    if (selectedMode) {
      setHasStarted(true);
    } else {
      alert('Por favor, selecciona un modo de entrenamiento.');
    }
  };

  const loadRoutine = (routine) => {
    if (!routine.exercises || routine.exercises.length === 0 || !routine.restTime) {
      alert('La rutina guardada está incompleta o tiene un formato incorrecto.');
      return;
    }
    setCustomRoutine(routine.exercises);
    setCustomRestTime(routine.restTime);
    setHasStarted(true);
  };

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-purple-900 via-gray-900 to-black">
      <div className="transition-all duration-300 flex-grow p-4 mx-auto max-w-4xl">
        <h1 className="text-2xl md:text-3xl font-bold text-center mb-4 md:mb-6 text-yellow-400">Entrenamiento</h1>
        {!customRoutine && selectedMode === "custom" && <CustomRoutineCreator onRoutineCreated={handleRoutineCreated} />}
        {!customRoutine && !hasStarted && (
          <div className="mb-4">
            <label htmlFor="mode" className="block text-lg font-semibold text-white mb-2">Selecciona el modo de entrenamiento:</label>
            <select
              id="mode"
              value={selectedMode}
              onChange={handleModeChange}
              className="w-full p-3 border border-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 text-black"
            >
              <option value="">--Selecciona un modo--</option>
              {Object.keys(modes).map((mode) => (
                <option key={mode} value={mode}>{modes[mode].name}</option>
              ))}
            </select>
          </div>
        )}
        {selectedMode && !hasStarted && (
          <div className="mb-4 p-4 bg-gray-800 rounded-lg shadow">
            <h3 className="text-lg font-semibold mb-2 text-yellow-300">{modes[selectedMode].name}</h3>
            <p className="text-base text-white">{modes[selectedMode].description}</p>
          </div>
        )}
        {!hasStarted && savedRoutines.length > 0 && (
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2 text-white">Rutinas Guardadas:</h3>
            {savedRoutines.map(routine => (
              <div key={routine.name} className="flex justify-between items-center p-3 bg-gray-700 rounded-lg mb-2">
                <span className="font-medium text-white">{routine.name}</span>
                <div className="flex space-x-2">
                  <button onClick={() => loadRoutine(routine)} className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-3 rounded-lg text-sm transition-all duration-300">Cargar</button>
                  <button onClick={() => deleteRoutine(routine.name)} className="bg-red-600 hover:bg-red-700 text-white py-1 px-3 rounded-lg text-sm transition-all duration-300">Eliminar</button>
                </div>
              </div>
            ))}
          </div>
        )}
        {hasStarted && customRoutine && <ExerciseTimer exercises={customRoutine} restTime={customRestTime} onRestart={handleRestart} />}
        {!hasStarted && <button onClick={handleStartClick} className="w-full bg-yellow-500 hover:bg-yellow-600 text-white py-3 px-4 rounded-lg mb-6 transition-all duration-300">Iniciar Entrenamiento</button>}
        {hasStarted && !customRoutine && <ExerciseTimer exercises={modes[selectedMode].exercises} restTime={modes[selectedMode].restTime} onRestart={handleRestart} />}
        {(customRoutine || hasStarted) && <button onClick={handleRestart} className="w-full bg-yellow-500 hover:bg-yellow-600 text-white py-3 px-4 rounded-lg mt-4 transition-all duration-300">Reiniciar y volver al menú</button>}
      </div>
    </div>
  );
}

export default App;
