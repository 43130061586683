import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import routes from './routes'; // Archivo con las rutas
import Layout from './components/common/Layout'; // Layout global predeterminado
import LayoutWithHeaderFooter from './components/common/LayoutWithHeaderFooter'; // Layout alternativo

const App = () => {
  return (
    <Router>
      <Routes>
        {routes.map(({ path, component: Component, layout }) => {
          // Usa el layout especificado o el predeterminado
          const SelectedLayout = layout === 'headerFooter' ? LayoutWithHeaderFooter : Layout;
          return (
            <Route
              key={path}
              path={path}
              element={
                <SelectedLayout>
                  <Component />
                </SelectedLayout>
              }
            />
          );
        })}
      </Routes>
    </Router>
  );
};

export default App;
