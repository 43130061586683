// src/components/common/LayoutWithHeaderFooter.js
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import FeedbackButton from './FeedbackButton';

const LayoutWithHeaderFooter = ({ children }) => (
  <div className="flex flex-col min-h-screen bg-gray-900 text-white layout-container">
    <Header />
    <main className="flex-grow p-8">
      {children}
      <FeedbackButton />
    </main>
    <Footer />
  </div>
);

export default LayoutWithHeaderFooter;
