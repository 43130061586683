import React from 'react';
import { motion } from 'framer-motion';
import { FaTiktok, FaNewspaper, FaGlobeAmericas, FaBolt, FaUsers } from 'react-icons/fa';

const NewsInfo = () => {
  return (
    <div className="flex min-h-screen bg-gradient-to-b from-gray-900 to-black text-white transition-all duration-300 p-8">
      <motion.div 
        className="max-w-4xl mx-auto space-y-12"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <header className="text-center">
          <motion.h1 
            className="text-6xl font-extrabold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500"
            initial={{ y: -50 }}
            animate={{ y: 0 }}
            transition={{ type: 'spring', stiffness: 300, damping: 10 }}
          >
            Fifty News
          </motion.h1>
          <p className="text-xl text-gray-300 mb-6">
            Tu fuente diaria de noticias y tendencias en TikTok
          </p>
          <motion.a 
            href="https://www.tiktok.com/@fifty_news" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="inline-flex items-center px-6 py-3 bg-[#00f2ea] text-black rounded-full font-bold text-lg hover:bg-[#00d2c6] transition duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <FaTiktok className="mr-2 text-2xl" /> Síguenos en TikTok
          </motion.a>
        </header>

        <motion.section 
          className="bg-gray-800 bg-opacity-50 p-8 rounded-2xl backdrop-blur-sm"
          whileHover={{ scale: 1.02 }}
          transition={{ duration: 0.3 }}
        >
          <h2 className="text-3xl font-bold mb-4 text-center">¿Qué ofrecemos?</h2>
          <p className="text-lg text-gray-300 mb-6 text-center">
            En Fifty News, te mantenemos al día con las noticias más relevantes y las tendencias más candentes, todo en formato de video corto y dinámico.
          </p>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            <FeatureCard icon={<FaNewspaper />} title="Noticias Diarias" />
            <FeatureCard icon={<FaGlobeAmericas />} title="Eventos Globales" />
            <FeatureCard icon={<FaBolt />} title="Tecnología" />
            <FeatureCard icon={<FaUsers />} title="Tendencias Sociales" />
          </div>
        </motion.section>

        <section className="text-center">
          <h2 className="text-3xl font-bold mb-4">¿Por qué Fifty News en TikTok?</h2>
          <ul className="text-lg text-gray-300 space-y-2">
            <li>✓ Noticias concisas y fáciles de entender</li>
            <li>✓ Contenido visual atractivo</li>
            <li>✓ Actualizaciones diarias</li>
            <li>✓ Interacción directa con nuestra comunidad</li>
          </ul>
        </section>
      </motion.div>
    </div>
  );
};

const FeatureCard = ({ icon, title }) => (
  <motion.div 
    className="bg-gray-700 p-4 rounded-lg text-center"
    whileHover={{ scale: 1.05 }}
    transition={{ type: 'spring', stiffness: 400, damping: 10 }}
  >
    <div className="text-4xl mb-2 text-blue-400">{icon}</div>
    <h3 className="font-bold">{title}</h3>
  </motion.div>
);

export default NewsInfo;
