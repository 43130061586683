// PokemonTable.jsx
import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

const PokemonTable = () => {
  const [pokemonList, setPokemonList] = useState([]);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSortedAsc, setIsSortedAsc] = useState(true);

  useEffect(() => {
    const storedData = localStorage.getItem("pokemonList");
    if (storedData) {
      setPokemonList(JSON.parse(storedData).sort((a, b) => a.name.localeCompare(b.name)));
      setLoading(false);
    } else {
      fetch("/MewRequired.json")
        .then((response) => {
          if (!response.ok) {
            throw new Error("Error al cargar el archivo JSON, status: " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          if (!data.pokemon || !Array.isArray(data.pokemon)) {
            throw new Error("El archivo JSON no contiene una lista válida");
          }
          const initializedPokemon = data.pokemon
            .map(({ name, pack }) => ({
              name,
              pack,
              captured: false,
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
          setPokemonList(initializedPokemon);
          localStorage.setItem("pokemonList", JSON.stringify(initializedPokemon));
          setLoading(false);
        })
        .catch((err) => {
          console.error("Catch error:", err);
          setError(err.toString());
          setLoading(false);
        });
    }
  }, []);

  const handleCheckboxChange = (pokemonName) => {
    const newList = pokemonList.map((pokemon) =>
      pokemon.name === pokemonName ? { ...pokemon, captured: !pokemon.captured } : pokemon
    );
    setPokemonList(newList);
    localStorage.setItem("pokemonList", JSON.stringify(newList));
  };

  const handleResetSelections = () => {
    const resetList = pokemonList.map((pokemon) => ({ ...pokemon, captured: false }));
    setPokemonList(resetList);
    localStorage.setItem("pokemonList", JSON.stringify(resetList));
  };

  const handleSort = () => {
    setPokemonList((prevList) =>
      [...prevList].sort((a, b) =>
        isSortedAsc ? b.name.localeCompare(a.name) : a.name.localeCompare(b.name)
      )
    );
    setIsSortedAsc(!isSortedAsc);
  };

  const filteredPokemon = useMemo(() => {
    return pokemonList
      .filter((pokemon) =>
        pokemon.name.toLowerCase().includes(filter.toLowerCase())
      )
      .sort((a, b) => (isSortedAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)));
  }, [filter, pokemonList, isSortedAsc]);

  const calculatePercentage = () => {
    const total = pokemonList.length;
    const captured = pokemonList.filter((p) => p.captured).length;
    return total ? ((captured / total) * 100).toFixed(2) : 0;
  };

  const recommendPackDynamic = () => {
    const packs = pokemonList.reduce((acc, pokemon) => {
      if (!acc[pokemon.pack]) {
        acc[pokemon.pack] = { total: 0, captured: 0 };
      }
      acc[pokemon.pack].total += 1;
      if (pokemon.captured) {
        acc[pokemon.pack].captured += 1;
      }
      return acc;
    }, {});

    const sortedPacks = Object.entries(packs)
      .map(([packName, { total, captured }]) => ({
        packName,
        total,
        captured,
        remaining: total - captured,
      }))
      .sort((a, b) => b.remaining - a.remaining);

    return sortedPacks[0]?.packName || null;
  };

  if (loading) return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="text-white text-xl">Loading...</div>
    </div>
  );
  
  if (error) return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="text-red-500 text-xl">{error}</div>
    </div>
  );

  const percentage = calculatePercentage();
  const dynamicPackRecommendation = recommendPackDynamic();

  return (
    <>
      <Helmet>
        <title>Pokémon Capture Tracker - Optimize Your Progress</title>
        <meta
          name="description"
          content="Track and optimize your Pokémon capture progress with detailed recommendations based on your selected Pokémon packs."
        />
        <meta
          name="keywords"
          content="Pokémon, Capture Tracker, Pokémon Packs, Mew Progress, Track Progress, Pokémon Recommendation"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="flex flex-col lg:flex-row min-h-screen px-4 lg:px-10 py-8 bg-gradient-to-br from-gray-900 to-black space-y-8 lg:space-y-0 lg:space-x-10 justify-center">
        {/* Panel Izquierdo - Imagen y Progreso */}
        <motion.div
          className="lg:w-2/5 flex flex-col items-center justify-center bg-gray-800 rounded-xl shadow-lg p-4 lg:p-8 border border-violet-600"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="relative w-52 h-52 mb-6">
            <img
              src="/images/genetic-apex-a1-286-mew.png"
              alt="Mew"
              className="absolute inset-0 w-full h-full object-contain"
              style={{
                filter: `grayscale(${100 - percentage}%)`,
              }}
            />
            <div
              className="absolute inset-0 w-full h-full rounded-full bg-gradient-to-t from-violet-600 to-transparent"
              style={{
                clipPath: `polygon(0% ${100 - percentage}%, 100% ${100 - percentage}%, 100% 100%, 0% 100%)`,
              }}
            ></div>
          </div>
          <div className="text-white text-center">
            <p className="text-3xl font-bold">Captured Mew Progress</p>
            <p className="text-xl">{percentage}%</p>
          </div>
          <button
            onClick={handleResetSelections}
            className="mt-8 px-5 py-3 bg-red-600 text-white text-lg rounded shadow hover:bg-red-700 focus:outline-none transition-colors duration-200"
          >
            Reset Selections
          </button>
        </motion.div>

        {/* Panel Derecho - Tabla */}
        <motion.div
          className="lg:w-3/5 bg-gray-800 rounded-xl shadow-lg p-4 lg:p-8 text-white border border-violet-600"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-2xl lg:text-4xl font-bold mb-6 text-center">Pokémon Table</h1>
          
          <div className="mb-6 space-y-4">
            <input
              type="text"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              placeholder="Filter Pokémon"
              className="block w-full px-4 py-2 rounded bg-gray-700 border border-gray-600 text-white text-sm focus:outline-none focus:ring-2 focus:ring-violet-500 transition-all duration-200"
            />
            
            {dynamicPackRecommendation && (
              <div className="text-center bg-gray-700 p-3 rounded-lg border border-violet-500">
                <p className="text-yellow-400 text-base lg:text-lg font-semibold">
                  Recommended Pack: <span className="font-bold">{dynamicPackRecommendation}</span>
                </p>
              </div>
            )}
          </div>
          
          <div className="relative">
            <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-violet-600 scrollbar-track-gray-700">
              <table className="w-full min-w-[500px] text-left border-collapse">
                <thead className="sticky top-0 bg-gray-800 z-10">
                  <tr className="border-b border-gray-700">
                    <th className="p-2 text-center w-16">#</th>
                    <th className="p-2 text-center w-24">Captured</th>
                    <th
                      className="p-2 text-left cursor-pointer group"
                      onClick={handleSort}
                    >
                      <div className="flex items-center space-x-2">
                        <span>Pokémon</span>
                        <span className="text-violet-400 transition-transform duration-200 group-hover:scale-110">
                          {isSortedAsc ? "⬆" : "⬇"}
                        </span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPokemon.map((pokemon, index) => (
                    <tr 
                      key={index} 
                      className="border-b border-gray-700 hover:bg-gray-700 transition-colors duration-200"
                    >
                      <td className="p-2 text-center">{index + 1}</td>
                      <td className="p-2 text-center">
                        <input
                          type="checkbox"
                          checked={pokemon.captured}
                          onChange={() => handleCheckboxChange(pokemon.name)}
                          className="w-4 h-4 rounded border-gray-600 text-violet-600 focus:ring-violet-500 transition-colors duration-200"
                        />
                      </td>
                      <td className="p-2 text-left whitespace-normal break-words">
                        {pokemon.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            
            {/* Indicador de scroll */}
            <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-700">
              <div className="h-full bg-violet-600 w-1/3"></div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default PokemonTable;