import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const OAuthCallback = () => {
  const navigate = useNavigate();

  const exchangeCodeForToken = async (code) => {
    try {
      const response = await fetch('https://discord.com/api/oauth2/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          client_id: process.env.REACT_APP_CLIENT_ID_MORO,
          client_secret: process.env.REACT_APP_CLIENT_SECRET_DISCORD,
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: process.env.REACT_APP_REDIRECT_URI,
        }),
      });

      if (!response.ok) throw new Error('Failed to exchange code for token');

      const data = await response.json();
      return data.access_token;
    } catch (error) {
      console.error('Error exchanging code for token:', error);
      throw error;
    }
  };

  const getUserInfo = async (accessToken) => {
    try {
      const response = await fetch('https://discord.com/api/users/@me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) throw new Error('Failed to fetch user info');

      const userData = await response.json();
      return userData;
    } catch (error) {
      console.error('Error fetching user info:', error);
      throw error;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    if (code) {
      exchangeCodeForToken(code)
        .then((accessToken) => getUserInfo(accessToken))
        .then((userData) => {
          console.log('User Data:', userData);

          // Guardar los datos en sessionStorage
          sessionStorage.setItem('userData', JSON.stringify(userData));

          // Redirigir al perfil
          navigate('/');
        })
        .catch((error) => {
          console.error('Error during authentication process:', error);
          navigate('/error'); // Redirige a una página de error en caso de fallo
        });
    } else {
      console.error('No code found in URL');
      navigate('/error'); // Redirige a una página de error si no hay código
    }
  }, [navigate]);

  return <div>Procesando autenticación...</div>;
};

export default OAuthCallback;
